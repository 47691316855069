import HtmlIcon from "@mui/icons-material/Html"
import brokenImage from "@/assets/broken-image2.png"
import emptyImage from "@/assets/processing-image.jpeg"

export const MediaCardItem = ({
  fileType,
  uploadStatus,
  src,
  alt,
  isThumbnail,
}: {
  fileType: string
  uploadStatus: string
  src?: string
  alt: string
  isThumbnail: boolean
}) => {
  const isImage = !!fileType && !isThumbnail && fileType.indexOf("image") > -1
  const isHtml = !!fileType && fileType.indexOf("html") > -1

  if (uploadStatus === "failed" || !fileType) {
    return <ErrorImage src={brokenImage} alt={alt} />
  }

  if (
    (uploadStatus === "success" && isImage) ||
    (isThumbnail && uploadStatus === "success")
  ) {
    return (
      <Image
        uploadStatus={uploadStatus}
        src={src}
        alt={alt}
        fallbackSrc={emptyImage}
      />
    )
  }

  if (uploadStatus === "success" && isHtml) {
    return <HtmlFileIcon />
  }

  if (uploadStatus === "success" && !isImage) {
    return <Video src={src} />
  }

  return <ErrorImage src={emptyImage} alt={alt} />
}

export const ErrorImage = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <img
      src={src}
      alt={alt}
      width="100%"
      height="100%"
      style={{
        objectFit: "cover",
        objectPosition: "center",
        opacity: "50%",
      }}
    />
  )
}

export const Video = ({ src }: { src?: string }) => {
  return (
    <video
      src={src}
      playsInline
      width="100%"
      height="100%"
      style={{
        objectFit: "cover",
        height: "100%",
      }}
    ></video>
  )
}

export const Image = ({
  src,
  fallbackSrc,
  alt,
  uploadStatus,
}: {
  src?: string
  fallbackSrc: string
  alt: string
  uploadStatus: string
}) => {
  return (
    <img
      src={uploadStatus === "success" && !!src ? src : fallbackSrc}
      alt={alt}
      width="100%"
      height="100%"
      style={{
        objectFit: "cover",
        height: "100%",
      }}
    />
  )
}

export const HtmlFileIcon = () => {
  return (
    <HtmlIcon
      style={{
        fontSize: "100px",
        color: "#757575",
        display: "block",
        margin: "auto",
      }}
    />
  )
}
