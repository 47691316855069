import React from "react"
import { useNavigate } from "react-router-dom"
import {
  Close,
  EditOutlined,
  DeleteOutlined,
  SouthOutlined,
  Html as HtmlIcon,
} from "@mui/icons-material"
import {
  Drawer,
  IconButton,
  styled,
  Typography,
  Chip,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material"
import { ConfirmModal } from "@/components/ConfirmModal.js"
import {
  AiDataTypes,
  CreativeAiMetadataTypes,
  CreativeObjectType,
} from "@/model/creative"
import {
  useDeleteCreativeMutation,
  useGetAllAssetCategoriesQuery,
} from "@/app/services/tags"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { enqueueSnackbar } from "@/features/notifier/notifierSlice.js"
import { selectReadOnlyUser } from "@/features/auth/authSlice.js"
import { createCustomName, downloadS3AsBlob } from "@/util/downloadFromS3.js"
import { getUpdatedAiTags } from "@/util/getUpdatedAiTags.js"
import emptyImage from "@/assets/processing-image.jpeg"
import shareIcon from "@/assets/share-icon.png"

const drawerWidth = 500

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
}))

const styles = () => ({
  drawer: {
    overflow: "hidden",
    "& .MuiPaper-root": {
      width: drawerWidth,
    },
  },
  chip: {
    display: "inline-flex",
    padding: "4px 10px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    height: "auto",
    width: "auto",
    fontSize: "14px",
    lineHeight: "normal",
    borderRadius: "4px",
    border: "none",
    background: "#E9E9E9",
    textTransform: "capitalize",
    "&.MuiChip-clickable:hover": {
      backgroundColor: "#E9E9E9",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    "& .MuiChip-deleteIcon": {
      width: "12px",
      height: "12px",
      color: "#000",
    },
  },
  viewDetailsBtn: {
    backgroundColor: "#FFCB05",
    color: "#000",
    "&:hover": {
      backgroundColor: "#000",
      color: "#FFCB05",
    },
  },
})

type ExplorerDrawerProps = {
  isOpen: boolean
  data: CreativeObjectType
  handleClose: () => void
  handleClick?: () => void
  url?: string
}

export default function ExplorerDrawer({
  isOpen,
  handleClose,
  handleClick,
  data,
  url,
}: ExplorerDrawerProps) {
  const customStyles = styles()
  const dispatch = useAppDispatch()
  const ref = React.useRef(null)
  const navigate = useNavigate()

  const isReadOnly = useAppSelector(selectReadOnlyUser)

  const [tooltipTitle, setTooltipTitle] =
    React.useState<string>("Copy Share URL")
  const [isLoadingDownload, setIsLoadingDownload] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false)
  const [aiTagData, setAiTagData] = React.useState<AiDataTypes | null>(null)
  const [metaData, setMetadata] =
    React.useState<CreativeAiMetadataTypes | null>(null)
  const [assetCategory, setAssetCategory] = React.useState<{
    display_name: string
    category_id: string
  }>()

  const [deleteCreative, { isLoading: deleteIsLoading }] =
    useDeleteCreativeMutation()
  const { data: assetCategoryData } = useGetAllAssetCategoriesQuery()

  React.useEffect(() => {
    if (isOpen) {
      setAiTagData(getUpdatedAiTags(data))
      setMetadata(
        !!data.file_metadata
          ? data.file_metadata.data[data.file_data.file_type]
          : null,
      )
    }
  }, [isOpen, data])

  const handleDeleteConfirm = async () => {
    try {
      const result = await deleteCreative({
        creativeId: data.creative_id,
        status: data.file_data.upload_status,
      }).unwrap()
      setIsConfirmModalOpen(false)
      handleClose()
      dispatch(
        enqueueSnackbar({
          message: `Creative deleted successfully`,
          options: {
            key: "creative_delete_success",
            variant: "success",
          },
        }),
      )
    } catch (error) {
      setIsConfirmModalOpen(false)
      handleClose()
      dispatch(
        enqueueSnackbar({
          message: `Creative deletion failed`,
          options: {
            key: "creative_delete_failed",
            variant: "error",
          },
        }),
      )
    }
  }

  const handleDownload = () => {
    if (!!url && !isLoadingDownload) {
      setIsLoadingDownload(true)
      const fileName = createCustomName(
        data.creative_id,
        data.tag_data,
        data.file_data.s3_filename,
        assetCategory,
      )
      downloadS3AsBlob(url, fileName).finally(() => {
        setIsLoadingDownload(false)
      })
    }
  }

  const handleTagClick = (key: string, isAiKey = false) => {
    navigate(
      `/file-explorer${
        !localStorage.getItem("isListView") ||
        localStorage.getItem("isListView") === "true"
          ? "/list"
          : "/grid"
      }${
        !localStorage.getItem("tabName") ||
        localStorage.getItem("tabName") === "all"
          ? ""
          : "/" + localStorage.getItem("tabName")
      }?${isAiKey ? "ai_tag=" + key : `tag_${key}=` + data.tag_data[key]}`,
    )
  }

  const handleIECGeneration = (
    name: string,
    type: string,
    tagData: Record<string, string | string[]>,
  ) => {
    if (!!url) {
      const iecData = {
        name,
        type,
        url: url,
        tagData,
      }
      navigate("/iec", { state: iecData })
      handleClose()
    }
  }

  const getAssetCategory = () => {
    if (!!assetCategoryData) {
      setAssetCategory(
        assetCategoryData.find((category) =>
          Object.keys(data.tag_data).some((d) => d === category.category_id),
        ),
      )
    }
  }

  const copyToClipboard = (): void => {
    const textToCopy: string = `${window.location.origin}${location.pathname}?asset_id=${data.creative_id}`
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setTooltipTitle("Copied!")
        setTimeout(() => {
          setTooltipTitle("Copy Share URL")
        }, 2000) // Hide tooltip after 2 seconds
      })
      .catch((err: Error) => {
        console.error("Failed to copy: ", err)
      })
  }

  React.useEffect(() => {
    if (!!Object.keys(data).length) {
      getAssetCategory()
    }
  }, [data])

  return (
    <Drawer
      sx={customStyles.drawer}
      variant="temporary"
      anchor="right"
      open={isOpen && !!data.file_data}
      ref={ref}
      onClose={handleClose}
      transitionDuration={200}
    >
      {isOpen ? (
        <React.Fragment>
          <DrawerHeader
            sx={{
              height: "72px",
              justifyContent: "space-between",
              px: "20px",
              borderBottom: "1px solid #DDD",
            }}
          >
            <div className="flex">
              <p className="text-base font-medium">Detailed View</p>
              {/* {!!data.tag_data &&
            Object.keys(data.tag_data).length &&
            data.file_data.upload_status === "success" ? (
                {!isReadOnly ? (
                  <IconButton
                    sx={{ fontSize: "0.75em", p: 0 }}
                    onClick={handleClick}
                  >
                    Edit
                    <EditOutlined
                      sx={{ width: "22px", height: "22px", paddingLeft: "6px" }}
                    />
                  </IconButton>
                ) : null}
                <Tooltip
                  title={tooltipTitle}
                  disableFocusListener
                  disableTouchListener
                >
                  <IconButton
                    sx={{ fontSize: "0.75em", p: 0, color: "#0D99FF" }}
                    onClick={copyToClipboard}
                  >
                    Share
                    <ShareOutlined
                      sx={{ width: "22px", height: "22px", paddingLeft: "6px" }}
                    />
                  </IconButton>
                </Tooltip>
            ) : null} */}
            </div>
            <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
              <Close />
            </IconButton>
          </DrawerHeader>
          {!!data.file_data ? (
            <div className="flex flex-wrap gap-10 p-5">
              <div className="relative w-full">
                <div className="flex flex-col gap-5 mb-5">
                  <button
                    className={`w-[89px] h-5 ${
                      data.file_data.file_type === "text/html"
                        ? "bg-neutral-gray4"
                        : "bg-[#FFF970]"
                    } rounded-sm text-xs`}
                    onClick={() =>
                      handleIECGeneration(
                        data.file_data.filename,
                        data.file_data.file_type,
                        data.tag_data,
                      )
                    }
                  >
                    Convert to IEC
                  </button>
                  <div className="flex items-center justify-between">
                    <div className="flex gap-[10px] items-center">
                      {!isReadOnly ? (
                        <Button
                          variant="text"
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: 400,
                            textTransform: "capitalize",
                            minWidth: "unset",
                            p: 0,
                          }}
                          onClick={handleClick}
                        >
                          <EditOutlined
                            sx={{ fill: "#000", fontSize: "20px", mr: "6px" }}
                          />
                          Edit
                        </Button>
                      ) : null}
                      {data.file_data.upload_status === "success" ? (
                        isLoadingDownload ? (
                          <CircularProgress size="20px" />
                        ) : (
                          <Button
                            variant="text"
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: 400,
                              textTransform: "capitalize",
                              minWidth: "unset",
                              p: 0,
                            }}
                            onClick={handleDownload}
                          >
                            <SouthOutlined
                              sx={{ fill: "#000", fontSize: "20px", mr: "6px" }}
                            />
                            Download
                          </Button>
                        )
                      ) : null}
                      <Tooltip
                        title={tooltipTitle}
                        disableFocusListener
                        disableTouchListener
                      >
                        <Button
                          variant="text"
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: 400,
                            textTransform: "capitalize",
                            minWidth: "unset",
                            p: 0,
                          }}
                          onClick={copyToClipboard}
                        >
                          <img
                            src={shareIcon}
                            width="20px"
                            height="20px"
                            style={{ marginRight: "6px" }}
                          />
                          Share
                        </Button>
                      </Tooltip>
                    </div>
                    <Button
                      variant="text"
                      sx={{
                        color: "#EB4335",
                        fontSize: "14px",
                        fontWeight: 400,
                        textTransform: "capitalize",
                        minWidth: "unset",
                        p: 0,
                      }}
                      onClick={() => {
                        setIsConfirmModalOpen(true)
                      }}
                    >
                      <DeleteOutlined
                        sx={{ fill: "#EB4335", fontSize: "20px", mr: "6px" }}
                      />
                      Delete
                    </Button>
                  </div>
                </div>
                {data.file_data.file_type.split("/")[0] === "image" ? (
                  <img
                    src={!!url ? url : emptyImage}
                    alt={data.creative_id}
                    width="100%"
                    style={{
                      objectFit: "cover",
                      borderRadius: "6px",
                      height: "300px",
                    }}
                  ></img>
                ) : data.file_data.file_type.split("/")[1] === "html" ? (
                  <HtmlIcon
                    style={{
                      fontSize: "100px",
                      color: "#757575",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                ) : (
                  <video
                    src={!!url ? url : ""}
                    playsInline
                    controls
                    loop
                    width="100%"
                    style={{
                      objectFit: "contain",
                      borderRadius: "6px",
                      height: "300px",
                    }}
                    controlsList="nodownload"
                    disablePictureInPicture
                  ></video>
                )}
              </div>
              <div className="flex overflow-auto sm:max-h-[200px] md:max-h-[300px] pb-2">
                <div className="flex flex-col w-auto gap-5">
                  {!!metaData ? (
                    <React.Fragment>
                      <div className="flex gap-3 flex-nowrap">
                        <Typography
                          fontWeight={700}
                          variant="subtitle2"
                          whiteSpace="nowrap"
                          minWidth="90px"
                        >
                          Duration
                        </Typography>
                        <Typography fontWeight={500} variant="caption">
                          :
                        </Typography>
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {metaData.duration}
                        </Typography>
                      </div>

                      <div className="flex gap-3 flex-nowrap">
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          whiteSpace="nowrap"
                          minWidth="90px"
                        >
                          Dimensions
                        </Typography>
                        <Typography fontWeight={500} variant="caption">
                          :
                        </Typography>
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {metaData.dimensions.join(" ")}
                        </Typography>
                      </div>
                      <div className="flex gap-3 flex-nowrap">
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          whiteSpace="nowrap"
                          minWidth="90px"
                        >
                          File Size
                        </Typography>
                        <Typography fontWeight={500} variant="caption">
                          :
                        </Typography>
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {`${(metaData.file_size / (1024 * 1024)).toFixed(
                            2,
                          )} MB`}
                        </Typography>
                      </div>
                      <div className="flex gap-3 flex-nowrap">
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          whiteSpace="nowrap"
                          minWidth="90px"
                        >
                          FPS
                        </Typography>
                        <Typography fontWeight={500} variant="caption">
                          :
                        </Typography>
                        <Typography
                          fontWeight={500}
                          variant="caption"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {metaData.fps}
                        </Typography>
                      </div>
                    </React.Fragment>
                  ) : null}
                  {data.file_data.upload_status !== "success" ? (
                    <div className="flex gap-3 flex-nowrap">
                      <Typography
                        fontWeight={500}
                        variant="caption"
                        whiteSpace="nowrap"
                        minWidth="90px"
                      >
                        Status
                      </Typography>
                      <Typography fontWeight={500} variant="caption">
                        :
                      </Typography>
                      <Typography
                        fontWeight={500}
                        variant="caption"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {data.file_data.upload_status}
                      </Typography>
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col gap-[30px]">
                  <div className="flex flex-col items-start gap-3">
                    <Typography fontWeight={700} variant="subtitle2">
                      Media Type
                    </Typography>
                    <Chip
                      label={
                        data.file_data.file_type.indexOf("video") > -1
                          ? "video"
                          : data.file_data.file_type.indexOf("html") > -1
                          ? "html"
                          : "image"
                      }
                      variant="outlined"
                      sx={customStyles.chip}
                    />
                  </div>
                  {!!assetCategory ? (
                    <div className="flex flex-col items-start gap-3">
                      <Typography fontWeight={700} variant="subtitle2">
                        Asset Category
                      </Typography>
                      <Chip
                        label={assetCategory.display_name}
                        variant="outlined"
                        sx={customStyles.chip}
                      />
                    </div>
                  ) : null}
                  {!!Object.keys(data.tag_data).length ? (
                    <div className="flex flex-col items-start gap-3">
                      <Typography fontWeight={700} variant="subtitle2">
                        Tags
                      </Typography>
                      <div className="flex gap-[18px] flex-wrap">
                        {Object.keys(data.tag_data).map((key) => {
                          if (
                            !!assetCategory &&
                            key === assetCategory.category_id
                          ) {
                            return null
                          } else {
                            return (
                              <Chip
                                key={key}
                                label={data.tag_data[key]}
                                variant="outlined"
                                onClick={() => handleTagClick(key)}
                                sx={customStyles.chip}
                              />
                            )
                          }
                        })}
                      </div>
                    </div>
                  ) : null}
                  {!!aiTagData &&
                  !!aiTagData.ai_tag_data.labels_names.length ? (
                    <div className="flex flex-col items-start gap-3">
                      <Typography fontWeight={700} variant="subtitle2">
                        Ai Tags
                      </Typography>
                      <div className="flex gap-[18px]">
                        {aiTagData.ai_tag_data.labels_names.map((key) => (
                          <Chip
                            key={key}
                            label={key}
                            variant="outlined"
                            onClick={() => handleTagClick(key, true)}
                            sx={customStyles.chip}
                          />
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </React.Fragment>
      ) : null}
      <ConfirmModal
        open={isConfirmModalOpen}
        confirmText="Do you really want to delete the asset, this action cannot be reversed"
        handleClose={() => {
          setIsConfirmModalOpen(false)
          handleClose()
        }}
        isLoading={deleteIsLoading}
        handleSubmit={() => handleDeleteConfirm()}
      />
    </Drawer>
  )
}
