import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "@/app/store"
import { UploadFileDataType } from "@/model/upload"

type State = {
  version_number: number
  selectedCreatives: UploadFileDataType[]
}

const initialState: State = {
  version_number: 0,
  selectedCreatives: [],
}

const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    setVersionNumber: (state, action: PayloadAction<number>) => {
      state.version_number = action.payload
    },
    setSelectedCreatives: (
      state,
      action: PayloadAction<UploadFileDataType[]>,
    ) => {
      state.selectedCreatives = action.payload
    },
  },
})

export const { setVersionNumber, setSelectedCreatives } = tagSlice.actions
export default tagSlice.reducer

export const getSelectedCreatives = (state: RootState) =>
  state.tag.selectedCreatives
