import React from "react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Typography,
  Grid,
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  FormControlLabel,
  Box,
  Tooltip,
} from "@mui/material"
import {
  Apps,
  ArrowDropDown,
  Close,
  DeleteOutline,
  FormatListBulletedOutlined,
  MoreHoriz,
} from "@mui/icons-material"
import { useDeleteCreativesMutation } from "@/app/services/tags"
import { useGetDefaultSearchQuery } from "@/app/services/search.js"
import { useAppDispatch } from "@/app/hooks.js"
import { useGetCreativeUrlsQuery } from "@/app/services/app"
import { UploadStatus } from "@/model/search"
import { CreativeObjectType } from "@/model/creative.js"
import { enqueueSnackbar } from "@/features/notifier/notifierSlice.js"
import { MediaCard } from "@/components/MediaCard.js"
import ViewAllMenu from "@/components/ViewAllMenu.js"
import ExplorerDrawer from "@/components/ExplorerDrawer.js"
import { ConfirmModal } from "@/components/ConfirmModal.js"
import { HomePageStyles } from "@/pages/HomePage/HomePageStyles.js"

export type ViewAllTypes =
  | "processing"
  | "recently-uploaded"
  | "failed"
  | "videos"
  | "images"

type HomeViewAllProps = {
  options: ViewAllTypes[]
  selectedOption: UploadStatus
}

export const HomeViewAll = ({ options, selectedOption }: HomeViewAllProps) => {
  const customStyles = HomePageStyles()
  const ref = React.useRef(null)
  const popperRef = React.useRef(null)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const selectedType = useLocation().pathname

  const { state } = useLocation()

  const [selectedCards, setSelectedCards] = React.useState(
    [] as CreativeObjectType[],
  )

  const [selectedCard, setSelectedCard] = React.useState(
    {} as CreativeObjectType,
  )

  const [isDrawerOpen, setisDrawerOpen] = React.useState(false)

  const [isListView, setIsListView] = React.useState(
    state && state.view === "grid" ? false : true,
  )

  const [isPopperOpen, setIsPopperOpen] = React.useState(false)

  const [viewData, setViewData] = React.useState([] as CreativeObjectType[])

  const [currentPage, setCurrentPage] = React.useState(1)

  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false)

  const { data: viewAllData, refetch } = useGetDefaultSearchQuery({
    page_size: 12,
    status: selectedOption,
    page_num: (currentPage - 1) * 12,
  })

  const { data: creativeUrlsData } = useGetCreativeUrlsQuery(
    !!viewAllData && selectedOption === "success"
      ? {
          creative_ids: viewAllData.data.map((d) => d.creative_id),
          thumbnail: true,
        }
      : skipToken,
  )

  const [deleteCreatives, { isLoading: deleteIsLoading }] =
    useDeleteCreativesMutation()

  React.useEffect(() => {
    refetch()
      .unwrap()
      .finally(() => {
        setSelectedCards([])
      })
  }, [currentPage])

  const handleAddSelected = (
    event: React.ChangeEvent<HTMLInputElement>,
    obj: CreativeObjectType,
  ) => {
    if (event.target.checked) {
      setSelectedCards([...selectedCards, obj])
    } else {
      const newArr = selectedCards.filter(
        (data) => data.creative_id !== obj.creative_id,
      )
      setSelectedCards([...newArr])
    }
  }

  const handleSelectedType = (val: ViewAllTypes) => {
    navigate(`/${val}`)
  }

  const handleDetails = (row: CreativeObjectType) => {
    setSelectedCard(row)
    if (selectedCard.creative_id === row.creative_id) {
      setisDrawerOpen(!isDrawerOpen)
    } else setisDrawerOpen(true)
  }

  const handleMultiDelete = async () => {
    try {
      const result = await deleteCreatives({
        creativeIds: selectedCards.map((card) => card.creative_id),
        status: selectedCards.map((card) => card.file_data.upload_status),
      }).unwrap()
      setIsConfirmModalOpen(false)
      setSelectedCards([])
      dispatch(
        enqueueSnackbar({
          message: `Creatives deleted successfully`,
          options: {
            key: "creative_delete_success",
            variant: "success",
          },
        }),
      )
    } catch (error) {
      setIsConfirmModalOpen(false)
      dispatch(
        enqueueSnackbar({
          message: `Creatives deletion failed`,
          options: {
            key: "creative_delete_failed",
            variant: "error",
          },
        }),
      )
    }
  }

  React.useEffect(() => {
    if (viewAllData) {
      setViewData(viewAllData.data)
    }
  }, [viewAllData])

  return (
    <React.Fragment>
      <Grid
        container
        flexDirection="column"
        p="48px 24px 24px 24px"
        sx={{ gap: { xs: 3, md: 6 } }}
      >
        <Grid
          ref={ref}
          container
          direction="column"
          item
          sx={{ gap: { xs: "24px", md: "2px" } }}
          justifyContent="space-between"
        >
          <Grid container item alignItems="center">
            <Typography
              variant="h6"
              fontWeight={700}
              textTransform="capitalize"
            >
              {selectedType === "/recently-uploaded"
                ? "Uploaded"
                : selectedType === "/processing"
                ? "Processing"
                : "Failed"}{" "}
              Assets
            </Typography>
            <ArrowDropDown
              ref={popperRef}
              sx={{ cursor: "pointer" }}
              onClick={() => setIsPopperOpen(!isPopperOpen)}
            />
          </Grid>
          <Grid
            container
            item
            width="auto"
            gap={3}
            justifyContent="flex-end"
            mt={3}
          >
            <ToggleButtonGroup>
              {isListView ? (
                <Tooltip title="Change to grid layout">
                  <ToggleButton
                    value="grid"
                    onClick={() => setIsListView(!isListView)}
                    sx={{
                      border: "none",
                      p: 0,
                      "& .MuiSvgIcon-root": {
                        fontSize: "30px",
                        color: "#000",
                      },
                    }}
                  >
                    <Apps />
                  </ToggleButton>
                </Tooltip>
              ) : (
                <Tooltip title="Change to list layout">
                  <ToggleButton
                    value="list"
                    onClick={() => setIsListView(!isListView)}
                    sx={{
                      border: "none",
                      p: 0,
                      "& .MuiSvgIcon-root": {
                        fontSize: "30px",
                        color: "#000",
                      },
                    }}
                  >
                    <FormatListBulletedOutlined />
                  </ToggleButton>
                </Tooltip>
              )}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        {!!selectedCards.length ? (
          <Grid
            container
            item
            alignItems="center"
            gap={1}
            pl={3}
            sx={{
              borderRadius: "20px",
              background: "#B7DDF7",
              boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.05)",
            }}
          >
            <IconButton
              onClick={() => {
                setSelectedCards([])
              }}
            >
              <Close />
            </IconButton>
            <Typography variant="body1" fontWeight={500} lineHeight="30px">
              {selectedCards.length} selected
            </Typography>
            <IconButton
              onClick={() => {
                setIsConfirmModalOpen(true)
              }}
            >
              <DeleteOutline />
            </IconButton>
          </Grid>
        ) : null}
        <Box sx={{ display: "flex", gap: isDrawerOpen ? "40px" : "0px" }}>
          {!!viewAllData && !!viewAllData.data.length ? (
            <React.Fragment>
              <Grid
                container
                p={6}
                justifyContent="space-between"
                sx={{
                  borderRadius: "14px",
                  background: "#FFF",
                  boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.05)",
                }}
              >
                {isListView ? (
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="center">Date</TableCell>
                          <TableCell align="center">Type</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {viewData.map((row) => (
                          <TableRow
                            key={row.creative_id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              height: "70px",
                            }}
                            onClick={() => handleDetails(row)}
                          >
                            <TableCell component="th" scope="row">
                              <FormControlLabel
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontSize: "14px",
                                    color: "#757575",
                                  },
                                }}
                                control={
                                  row.file_data.upload_status === "failed" ||
                                  (!!Object.keys(row.tag_data).length &&
                                    row.file_data.upload_status ===
                                      "uploading") ? (
                                    <></>
                                  ) : (
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: "1rem",
                                        },
                                      }}
                                      checked={
                                        !!selectedCards.find(
                                          (card) =>
                                            card.creative_id ===
                                            row.creative_id,
                                        )
                                      }
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                      ) => handleAddSelected(e, row)}
                                    />
                                  )
                                }
                                label={row.file_data.filename}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={customStyles.tableCell}
                            >
                              {new Date(row.created_at).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                },
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={customStyles.tableCell}
                            >
                              {row.file_data.file_type.indexOf("image") > -1
                                ? "image"
                                : "video"}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={customStyles.tableCell}
                            >
                              <Grid
                                container
                                width="auto"
                                justifyContent="space-around"
                              >
                                <IconButton
                                  sx={{ padding: "0px" }}
                                  onClick={() => handleDetails(row)}
                                >
                                  <MoreHoriz sx={{ width: "20px" }} />
                                </IconButton>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Box sx={customStyles.flexCardContainer}>
                    {viewData.map((obj) => (
                      <MediaCard
                        key={obj.creative_id}
                        {...obj}
                        isSelected={
                          !!selectedCards.find(
                            (card) => card.creative_id === obj.creative_id,
                          )
                        }
                        onSelect={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleAddSelected(e, obj)
                        }
                        urlObj={
                          !!creativeUrlsData
                            ? creativeUrlsData[obj.creative_id]
                            : undefined
                        }
                        onCardDetailSelect={() => {
                          setSelectedCard(obj)
                        }}
                        selectedCards={selectedCards}
                      />
                    ))}
                  </Box>
                )}
              </Grid>
            </React.Fragment>
          ) : (
            <Grid container alignItems="center" justifyContent="center" mt={4}>
              <Typography variant="h6">No results</Typography>
            </Grid>
          )}
          <ExplorerDrawer
            isOpen={isDrawerOpen}
            handleClose={() => {
              setisDrawerOpen(false)
              setSelectedCard({} as CreativeObjectType)
            }}
            data={selectedCard}
            url={
              !!creativeUrlsData && !!selectedCard.creative_id
                ? creativeUrlsData[selectedCard.creative_id]?.asset || ""
                : undefined
            }
          />
        </Box>
      </Grid>
      <ConfirmModal
        open={isConfirmModalOpen}
        confirmText="Do you really want to delete the assets, this action cannot be reversed"
        handleClose={() => {
          setIsConfirmModalOpen(false)
        }}
        isLoading={deleteIsLoading}
        handleSubmit={() => handleMultiDelete()}
      />
      <ViewAllMenu
        isOpen={isPopperOpen}
        anchorEl={popperRef.current}
        handleClose={() => setIsPopperOpen(false)}
        handleClick={handleSelectedType}
        options={options}
      />
    </React.Fragment>
  )
}
