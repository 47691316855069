import { AuthenticationResultType } from "@aws-sdk/client-cognito-identity-provider"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { RootState } from "@/app/store"
import { ApiError } from "@/model/response"
import isFetchBaseQueryError from "@/util/isFetchBaseQueryError"
import { enqueueSnackbar } from "@/features/notifier/notifierSlice"
import { getTokens, refreshAuth } from "@/features/auth/cognito"

type State = {
  id: string | null
  email: string | null
  isAuthenticated: boolean
  isAuthenticating: boolean
  userType: "admin" | "user"
  permissionModules: string[]
  loginProvider: "email" | "sso" | null
  authenticationResult: AuthenticationResultType | null
  isReadOnly: boolean
}

const regexPattern = (val: "Admin" | "User") =>
  new RegExp(`CreativeManager#[A-Za-z]+#${val}`, "gm")

const initialState: State = {
  id: null,
  email: null,
  userType: "user",
  permissionModules: [],
  isAuthenticated: false,
  isAuthenticating: false,
  loginProvider: null,
  authenticationResult: null,
  isReadOnly: false,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string | null>) => {
      state.email = action.payload
    },
    setLoginProvider: (
      state,
      action: PayloadAction<State["loginProvider"]>,
    ) => {
      state.loginProvider = action.payload
    },
    setUserType: (state, action: PayloadAction<State["userType"]>) => {
      state.userType = action.payload
    },
    handleLogin: (state, action: PayloadAction<AuthenticationResultType>) => {
      state.authenticationResult = action.payload
    },
    handleRefresh: (state, action: PayloadAction<AuthenticationResultType>) => {
      state.authenticationResult = {
        ...state.authenticationResult,
        ...action.payload,
      }
    },
    handleLogout: (state) => {
      state.email = null
      state.authenticationResult = null
      state.userType = "user"
    },
    checkReadOnly: (state, action: PayloadAction<boolean>) => {
      state.isReadOnly = action.payload
    },
  },
})

export const googleAuth = createAsyncThunk<
  AuthenticationResultType,
  { code: string }
>(
  `${authSlice.name}/googleAuth`,
  async ({ code }, { dispatch, rejectWithValue, getState }) => {
    const state = getState() as RootState
    dispatch(checkReadOnly(false))
    try {
      const tokens = await getTokens(code)
      if (!tokens.access_token) {
        return rejectWithValue("Invalid token")
      }

      const authenticationResult = {
        AccessToken: tokens.access_token,
        RefreshToken: tokens.refresh_token,
        ExpiresIn: tokens.expires_in,
        TokenType: tokens.token_type,
        IdToken: tokens.id_token,
      }

      if (authenticationResult.AccessToken) {
        const b64 = authenticationResult.AccessToken.slice(
          authenticationResult.AccessToken.indexOf(".") + 1,
          authenticationResult.AccessToken.lastIndexOf("."),
        )
        const accessInfo = JSON.parse(atob(b64))

        const cognitoGroups = accessInfo["cognito:groups"] as string[]
        if (!!cognitoGroups) {
          const checkAdmin = cognitoGroups.find((str) =>
            regexPattern("Admin").test(str),
          )
          const checkValidUser = cognitoGroups.find((str) =>
            regexPattern("User").test(str),
          )

          if (checkAdmin) {
            dispatch(setUserType("admin"))
          }

          if (!checkValidUser && !checkAdmin) {
            dispatch(checkReadOnly(true))
          }
        } else {
          dispatch(authSlice.actions.handleLogout())
          throw new Error(
            "You dont have the correct permission. Please contact the app admin",
          )
        }
      }

      if (authenticationResult.IdToken) {
        const b64 = authenticationResult.IdToken.slice(
          authenticationResult.IdToken.indexOf(".") + 1,
          authenticationResult.IdToken.lastIndexOf("."),
        )
        const info = JSON.parse(atob(b64))
        dispatch(setEmail(info.email))
      }

      dispatch(setLoginProvider("sso"))
      dispatch(handleLogin(authenticationResult))

      dispatch(
        enqueueSnackbar({
          message: "Logged in successfully!",
          options: {
            key: "login_success",
            variant: "success",
          },
        }),
      )

      return authenticationResult
    } catch (e: any) {
      console.log(e)
      // Notify user
      let message = "An error occurred while logging in."
      let error: ApiError | undefined
      if ("error" in e && isFetchBaseQueryError(e.error)) {
        const data = e.error.data as ApiError
        if (data) {
          error = data

          if (e.error.status === 404) {
            message = "A user with that email address could not be found..."
          } else if (e.error.status === 401) {
            message = "Incorrect password."
          } else if (error.error_message) {
            message = error.error_message
          }
        }
      }

      dispatch(
        enqueueSnackbar({
          message,
          options: {
            key: "login_error",
            variant: "error",
          },
        }),
      )
    }

    return rejectWithValue("Unknown error")
  },
)

export const refresh = createAsyncThunk<
  AuthenticationResultType,
  { token: string }
>(
  `${authSlice.name}/refresh`,
  async ({ token }, { dispatch, rejectWithValue }) => {
    try {
      const response = await refreshAuth(token)
      if (!response.AuthenticationResult) {
        return rejectWithValue("No AuthenticationResult")
      }

      dispatch(handleRefresh(response.AuthenticationResult))

      return response.AuthenticationResult
    } catch (e) {
      if (e instanceof Error) {
        return rejectWithValue(e.message)
      }
    }

    return rejectWithValue("Unknown error")
  },
)

const persistConfig = {
  key: authSlice.name,
  version: 1,
  storage,
}

const { reducer } = authSlice
export default persistReducer(persistConfig, reducer)

export const {
  handleLogin,
  setEmail,
  setLoginProvider,
  setUserType,
  handleLogout,
  handleRefresh,
  checkReadOnly,
} = authSlice.actions

export const selectEmail = (state: RootState) => state.auth.email

export const selectIsAuthenticating = (state: RootState) =>
  state.auth.isAuthenticating

export const selectIsAuthenticated = (state: RootState) =>
  !!state.auth.authenticationResult

export const selectUserType = (state: RootState) => state.auth.userType

export const selectReadOnlyUser = (state: RootState) => state.auth.isReadOnly
