import { Button } from "@mui/material"
import { StepType, TourProvider } from "@reactour/tour"
import App from "@/App.js"

const tourSteps = [
  {
    selector: ".first-step",
    content:
      "Here you will find all the files on synology which are yet to be processed.",
    position: [355, 200],
  },
  {
    selector: ".second-step",
    content: "Here you will find all the files available to explore.",
    position: [355, 275],
  },
  {
    selector: ".third-step",
    content:
      "In this section, the admin has the ability to create a tag type , tag value, and make edits to both",
    position: [355, 400],
  },
  {
    selector: ".fourth-step",
    content:
      "In the Add Tag button, users can attach tags to assets that have been uploaded from both the local system and Synology",
    position: [1255, 200],
  },
  {
    selector: ".fifth-step",
    content:
      "Click the checkbox on hover to select the asset and double click to view the details",
    position: [755, 500],
  },
  // ...
] as StepType[]

export const AppTour = () => {
  return (
    <TourProvider
      steps={tourSteps}
      showBadge={false}
      styles={{
        popover: (base) => ({
          ...base,
          "--reactour-accent": "#FFCB05",
          borderRadius: 0,
        }),
      }}
      prevButton={({ currentStep, setCurrentStep, steps }) => {
        const first = currentStep === 0
        return (
          <Button
            sx={{
              color: "#FFCB05",
              "&:hover": {
                backgroundColor: "#242426",
                color: "#FFCB05",
              },
            }}
            onClick={() => {
              if (!!steps?.length) {
                if (first) {
                  setCurrentStep((s) => steps.length - 1)
                } else {
                  setCurrentStep((s) => s - 1)
                }
              }
            }}
          >
            Back
          </Button>
        )
      }}
      nextButton={({
        currentStep,
        stepsLength,
        setIsOpen,
        setCurrentStep,
        steps,
      }) => {
        const last = currentStep === stepsLength - 1
        return (
          <Button
            sx={{
              backgroundColor: "#FFCB05",
              color: "#000",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#242426",
                color: "#FFCB05",
              },
            }}
            variant="contained"
            onClick={() => {
              if (!!steps?.length) {
                if (last) {
                  setIsOpen(false)
                } else {
                  setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
                }
              }
            }}
          >
            {last ? "Finish" : "Next"}
          </Button>
        )
      }}
    >
      <App />
    </TourProvider>
  )
}
